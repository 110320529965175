import React from 'react'
import Wheel from '../svg/wheel.svg'
import Chart from '../svg/chart.svg'
import People from '../svg/people.svg'
import Carl from '../svg/cara.svg'
import Carr from '../svg/carr.svg'
import Wash from '../svg/wash.svg'
import Carwash from '../svg/carwash.svg'
import Card from '../svg/card.svg'
import Hands from '../svg/hands.svg'

const Icon = ({type}) => {
    switch (type) {
        case "wheel":
            return <Wheel/>
        case "chart":
            return <Chart/>
        case "people":
            return <People/>
        case "carr":
            return <Carr/>
        case "carl":
            return <Carl/>
        case "wash":
            return <Wash/>
        case "hands":
            return <Hands/>
        case "card":
            return <Card/>
        case "carwash":
            return <Carwash/>
        default:
            return <></>
    }
}

export default Icon
