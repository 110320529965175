import {Flex} from './UI'
import Feature from './Feature'
import React from 'react'
import styled from 'styled-components'
import Icon from './Icons'
import { breakpoints } from '../utils/helper'

const Container = styled(Flex)`
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 85%;
    
    @media ${breakpoints.tablet} {
        flex-direction: row;
        align-items: flex-start;
    }
`

const Features = ({data}) =>
    <Container>
        {data.map((item, i) =>
            <Feature key={`${i}-${item.title}`} title={item.title} body={item.body}>
                <Icon type={item.icon}/>
            </Feature>
        )}
    </Container>

export default Features
