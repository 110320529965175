import React from 'react'
import styled from 'styled-components'
import {Span, Title2, Flex} from './UI'
import { breakpoints } from '../utils/helper'

const Container = styled(Flex)`
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 90%;
    margin-bottom: 24px;
    
    @media ${breakpoints.tablet} {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 260px;
        margin-bottom: 0;
    }
`

const CopyBox = styled.div`
    justify-content: flex-start;
    top: 100px;
    
    span {
        display: block;
        text-align: left;
        
        @media ${breakpoints.tablet} {
            text-align: center;
        }
    }
`

const Icon = styled.div`
    width: 78px;
    height: 40px;
    flex-shrink: 0;
    
    svg {
        transform: scale(0.5);
        transform-origin: top left;
        
        @media ${breakpoints.tablet} {
            transform: scale(1);
            transform-origin: top left;
        }
    }
    
    @media ${breakpoints.tablet} {
        width: auto;
        height: 110px;
    }
`

const StyledTitle = styled(Title2)`
    text-align: left;
    white-space: pre-wrap;
    margin: -6px 0 6px;
    
    @media ${breakpoints.tablet} {
        text-align: center;
        margin: 0 0 18px;
    }
`

const Feature = ({children, title, body}) =>
    <Container>
        <Icon>
            {children}
        </Icon>
        <CopyBox>
            <StyledTitle>{title}</StyledTitle>
            <Span>{body}</Span>
        </CopyBox>
    </Container>

export default Feature
